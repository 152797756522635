import React from 'react';
import styled from 'styled-components';

//Payment methods
import mastercard_logo from 'images/mastercard-logo-small.svg';
import visa_logo from 'images/visa-logo-small.svg';
import applepay_logo from 'images/applepay-logo-small.svg';
import jcb_logo from 'images/jcb-logo-small.svg';
import multibanco_logo from 'images/multibanco_logo.svg';
import googlepay_logo from 'images/googlepay-logo-small.svg';
import paypal_logo from 'images/paypal-logo-small.svg';
import bizum_blue from 'images/bizum_blue.svg';
import discover_logo from 'images/discover-logo-small.svg';
import clickToPay from 'images/click-to-pay.png';
import sepa_dark from 'images/sepa_dark.svg';
import diners_logo from 'images/dinersclub-logo-small.svg';
import mbWay_logo from 'images/mbway-logo.svg';
import bancontract_logo from 'images/bancontact.svg';
import {ScreenSizes} from '../../types/responsive';
import {InternalPageLink, InternalPageSlug} from './links/Pages';

export const PartnersContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px 76px;
  flex-wrap: wrap;

  @media (max-width: ${ScreenSizes.sm}) {
    gap: 28px;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    justify-content: space-around;
  }
`;

export const StyledPartnerLogo = styled.div`
  opacity: 0.7;
  transition: 250ms opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 155px;
  min-width: 115px;

  :hover {
    opacity: 1;
  }

  img {
    width: auto;
    height: 100%;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`;

export type Logo = {
  src: string;
  slug?: InternalPageSlug;
  alt: string;
  height?: number;
};
const homeLogos: (dark: boolean) => Logo[] = (dark) => [
  {
    src: bizum_blue,
    slug: 'payment-methods/bizum',
    alt: 'Bizum',
    width: 115
  },
  {
    src: mastercard_logo,
    alt: 'Mastercard',
    height: 50
  },
  {
    src: visa_logo,
    alt: 'Visa',
    height: 30
  },
  {
    src: applepay_logo,
    slug: 'payment-methods/apple-pay',
    alt: 'Apple Pay',
    height: 36
  },
  {
    src: clickToPay,
    slug: 'payment-methods/click-to-pay',
    alt: 'Click To Pay',
    height: 38
  },
  {
    src: paypal_logo,
    slug: 'payment-methods/paypal',
    alt: 'PayPal',
    height: 32
  },
  {
    src: multibanco_logo,
    slug: 'payment-methods/multibanco',
    alt: 'Multibanco',
    height: 68
  },
  {
    src: mbWay_logo,
    slug: 'payment-methods/mb-way',
    alt: 'MB Way',
    height: 46
  },
  {
    src: googlepay_logo,
    slug: 'payment-methods/google-pay',
    alt: 'Google Pay',
    height: 28
  },
  {
    src: discover_logo,
    alt: 'Discover',
    height: 26
  },
  {
    src: sepa_dark,
    slug: 'payment-methods/sepa-direct-debit',
    alt: 'SEPA',
    height: 42
  },
  {
    src: diners_logo,
    alt: 'Dinners Club International',
    height: 42
  },
  {
    src: jcb_logo,
    alt: 'JCB',
    height: 50
  },
  {
    src: bancontract_logo,
    alt: 'Bancontact',
    height: 84
  }
];

export const PartnerLogo: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <StyledPartnerLogo
    style={{
      width: `${props.width ? props.width + 'px' : 'auto'}`,
      height: `${props.height ? props.height + 'px' : 'auto'}`
    }}>
    <img title={props.title} alt={props.alt} src={props.src} />
  </StyledPartnerLogo>
);

type Props = {
  dark?: boolean;
  extraLogos?: Logo[];
};
export const HomePaymentMethods: React.FC<Props> = ({dark = false, extraLogos = []}) => (
  <PartnersContainer>
    {[...homeLogos(dark), ...extraLogos].map((logo, i) => {
      if (logo.slug) {
        return (
          <InternalPageLink key={i} slug={logo.slug}>
            <PartnerLogo {...logo} />
          </InternalPageLink>
        );
      }
      return <PartnerLogo {...logo} key={i} />;
    })}
  </PartnersContainer>
);
